<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="전체매출 계산서 발행 현황보기">
      -전체 매출금액을 보실 수 있는 페이지 입니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <div class="dp-table w-100per">
            <CarrotDatePicker
              class="inline-datepicker"
              v-model="search.info.sdate"
              @change="sales.getSales()"
            ></CarrotDatePicker>
            ~
            <CarrotDatePicker
              class="inline-datepicker"
              v-model="search.info.edate"
              @change="sales.getSales()"
            ></CarrotDatePicker>
          </div>
          <div class="w-100per mt-50 mb-10 bold">법인 별 매출</div>
          <table class="table-col">
            <thead>
              <tr>
                <th>총 금액</th>
                <th v-for="(itm, i) in sales.corp" :key="i">{{ itm.corp }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <router-link
                    :to="{ name: 'documents-SalesManagementPublishList' }"
                  >
                    <span v-if="sales.step == ''"> {{ sales.total }} </span>
                    <span v-if="sales.step == '승인'">{{ sales.confirm }}</span>
                    <span v-if="sales.step == '미승인'">{{
                      sales.request
                    }}</span>
                  </router-link>
                </td>
                <td v-for="(itm, i) in sales.corp" :key="i">
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: { corp: itm.corp },
                    }"
                  >
                    <span class="btn-view"> {{ itm.total_cost }} </span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="w-100per mt-50 mb-10 bold">부문 별 매출</div>
          <table class="table-col">
            <thead>
              <tr>
                <th>총 금액</th>
                <th v-for="(itm, i) in sales.sector" :key="i">
                  {{ itm.sector }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <router-link
                    :to="{ name: 'documents-SalesManagementPublishList' }"
                  >
                    <span v-if="sales.step == ''">{{ sales.total }}</span>
                    <span v-if="sales.step == '승인'">{{ sales.confirm }}</span>
                    <span v-if="sales.step == '미승인'">{{
                      sales.request
                    }}</span>
                  </router-link>
                </td>
                <td v-for="(itm, i) in sales.sector" :key="i">
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: { idx_sector: itm.idx_sector },
                    }"
                  >
                    <span class="btn-view"> {{ itm.total_cost }} </span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="w-100per mt-50 mb-10 bold">본부 매출</div>
          <table class="table-col table-auto">
            <thead>
              <tr>
                <th>총 금액</th>
                <th v-for="(itm, i) in sales.office" :key="i">
                  {{ itm.office }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <router-link
                    :to="{ name: 'documents-SalesManagementPublishList' }"
                  >
                    <span v-if="sales.step == ''">{{ sales.total }}</span>
                    <span v-if="sales.step == '승인'">{{ sales.confirm }}</span>
                    <span v-if="sales.step == '미승인'">{{
                      sales.request
                    }}</span>
                  </router-link>
                </td>
                <td v-for="(itm, i) in sales.office" :key="i">
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: { idx_office: itm.idx_office },
                    }"
                  >
                    <span class="btn-view"> {{ itm.total_cost }} </span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="w-100per mt-50 mb-10 bold">팀별 매출</div>
          <table class="table-col">
            <colgroup>
              <col span="8" />
            </colgroup>

            <thead>
              <tr>
                <th colspan="13">
                  총 매출 금액 :
                  <span class="color-1">{{ sales.confirm }}</span> 원
                  (예상매출액 :
                  <span class="color-4">{{ sales.total }}</span> 원)
                </th>
              </tr>
            </thead>

            <tbody v-for="(itm, idx) in sales.list" :key="idx">
              <tr>
                <th class="th" rowspan="2">팀</th>
                <th class="th" colspan="2">총매출</th>
                <th class="th" colspan="2">캐럿글로벌</th>
                <th class="th" colspan="2">캐럿솔루션즈</th>
                <th class="th" colspan="2">캐럿에듀</th>
                <th class="th" colspan="2">밝음</th>
                <th class="th" colspan="2">모리와함께</th>
              </tr>
              <tr>
                <th class="th">요청금액</th>
                <th class="th">승인금액</th>
                <th class="th">요청금액</th>
                <th class="th">승인금액</th>
                <th class="th">요청금액</th>
                <th class="th">승인금액</th>
                <th class="th">요청금액</th>
                <th class="th">승인금액</th>
                <th class="th">요청금액</th>
                <th class="th">승인금액</th>
                <th class="th">요청금액</th>
                <th class="th">승인금액</th>
              </tr>
              <tr v-for="(info, i) in itm" :key="i">
                <th class="th">{{ info.dept }}</th>
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: { idx_office: info.idx_office },
                    }"
                  >
                    <span class="btn-view"> {{ info.request_cost }} </span>
                  </router-link>
                </td>
                <!-- 총매출 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        sign_step: '팀장승인',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.comfirm_cost }} </span>
                  </router-link>
                </td>
                <!-- 총매출 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        corp: '캐럿글로벌',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c1_request_cost }} </span>
                  </router-link>
                </td>
                <!-- 캐럿글로벌 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        sign_step: '팀장승인',
                        corp: '캐럿글로벌',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c1_comfirm_cost }} </span>
                  </router-link>
                </td>
                <!-- 캐럿글로벌 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        corp: '캐럿솔루션즈',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c2_request_cost }} </span>
                  </router-link>
                </td>
                <!-- 캐럿솔루션즈 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        sign_step: '팀장승인',
                        corp: '캐럿솔루션즈',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c2_comfirm_cost }} </span>
                  </router-link>
                </td>
                <!-- 캐럿솔루션즈 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: { idx_office: info.idx_office, corp: '캐럿에듀' },
                    }"
                  >
                    <span class="btn-view"> {{ info.c3_request_cost }} </span>
                  </router-link>
                </td>
                <!-- 캐럿에듀 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        sign_step: '팀장승인',
                        corp: '캐럿에듀',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c3_comfirm_cost }} </span>
                  </router-link>
                </td>
                <!-- 캐럿에듀 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: { idx_office: info.idx_office, corp: '밝음' },
                    }"
                  >
                    <span class="btn-view"> {{ info.c4_request_cost }} </span>
                  </router-link>
                </td>
                <!-- 밝음 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        sign_step: '팀장승인',
                        corp: '밝음',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c4_comfirm_cost }} </span>
                  </router-link>
                </td>
                <!-- 밝음 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        corp: '모리와함께',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c5_request_cost }} </span>
                  </router-link>
                </td>
                <!-- 모리와함께 -->
                <td>
                  <router-link
                    :to="{
                      name: 'documents-SalesManagementPublishList',
                      params: {
                        idx_office: info.idx_office,
                        sign_step: '팀장승인',
                        corp: '모리와함께',
                      },
                    }"
                  >
                    <span class="btn-view"> {{ info.c5_comfirm_cost }} </span>
                  </router-link>
                </td>
                <!-- 모리와함께 -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import axios from "@/plugins/axios.js";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
// import { useStore } from 'vuex'
import { useToast } from "vue-toastification";

export default {
  layout: "documents",
  components: {
    CarrotDatePicker,
  },
  setup() {
    const toast = useToast();
    // const store = useStore()
    const search = reactive({
      info: {
        page: 1,
        sdate: "",
        edate: "",
        stype: "", // 매출 형태
        idx_office: 0,
        idx_team: 0,
        idx_staff: 0,
        idx_company: 0,
        state: "",
        corp: "",
        sector: "",
        rf: "",
        sign_step: "",
        office_report: null,
      },
      leftPad: (value) => {
        if (value >= 10) {
          return value;
        }
        return `0${value}`;
      },
      toStringByFormatting: (source, delimiter = "-") => {
        const year = source.getFullYear();
        const month = search.leftPad(source.getMonth() + 1);
        const day = search.leftPad(source.getDate());
        return [year, month, day].join(delimiter);
      },
    });
    const sales = reactive({
      isShow: false,
      list: [],
      selected: [],
      report: null,
      step: "",
      confirm: 0,
      request: 0,
      total: 0,
      number_format: (num) => {
        let price = num.toString().replace(/,/g, "");
        price = parseFloat(price);
        if (isNaN(price)) {
          price = 0;
        }
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      getSales: () => {
        let params = search.info;

        axios.get("/rest/sales/getReport", { params: params }).then((res) => {
          if (res.data.err == 0) {
            sales.list = res.data.list;
            sales.confirm = res.data.confirm;
            sales.total = res.data.total;
            sales.request = res.data.request;
            sales.corp = res.data.corp;
            sales.office = res.data.office;
            sales.sector = res.data.sector;
            sales.office_report = res.data.office_report;
            // sales.total = res.data.total;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });
    onMounted(() => {
      // Mounted
      let today = new Date();
      search.info.sdate = search.toStringByFormatting(
        new Date(today.getFullYear(), today.getMonth(), 1)
      );
      search.info.edate = search.toStringByFormatting(
        new Date(today.getFullYear(), today.getMonth() + 1, 0)
      );
      sales.getSales();
    });
    return { search, sales };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap .table-col.table-auto {
  table-layout: auto;
}
</style>
